import {
  PricingContainer,
  PricingHeading,
  PricingWrapper,
  PriceWrap,
  PriceImage,
  BoxHeading,
  PriceHeading,
  ActionButton,
  PayButton,
  HomeLesson
} from "./GetStartedElements";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import PriceImage2 from "../../images/pricing-img-2.svg";
import PriceImage3 from "../../images/pricing-img-3.svg";
import PriceImage4 from "../../images/pricing-img-4.svg";
import PriceImage5 from "../../images/pricing-img-5.svg";

const AllPrices = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <PricingContainer>
      <PricingHeading>Our home lesson pricing</PricingHeading>
      <PricingWrapper>
        <PriceWrap>
          <BoxHeading>Starter</BoxHeading>
          <PriceImage src={PriceImage5} alt="PriceImage" />
          <PriceHeading>Free Class</PriceHeading>
          <ul>
            <li>Maths & coding class</li>
            <li>1hr, once in a week</li>
            <li>Free Mentoring</li>
          </ul>
          <ActionButton
            href="https://classroom.google.com/c/NTU1MzE5OTk0OTQz?cjc=o55bxch"
            target="_blank"
          >
            Jump In
          </ActionButton>
        </PriceWrap>

        <PriceWrap>
          <BoxHeading>Academic Lesson</BoxHeading>
          <PriceImage src={PriceImage2} alt="PriceImage" />
          <PriceHeading>&#x20A6;5999 per hour</PriceHeading>
          <ul>
            <li>Academic lesson</li>
            <li>Choose your own time</li>
            <li>5% free data allowance</li>
          </ul>
          <PayButton to="/form" onClick={toggleHome}>Apply</PayButton>
        </PriceWrap>

        <PriceWrap>
          <BoxHeading>Tech Skill</BoxHeading>
          <PriceImage src={PriceImage3} alt="PriceImage" />
          <PriceHeading>&#x20A6;6799 per hour</PriceHeading>
          <ul>
            <li>Coding, or web design</li>
            <li>5% free data allowance</li>
            <li>Certificate of Completion</li>
          </ul>
          <PayButton to="/form" onClick={toggleHome}>Apply</PayButton>
        </PriceWrap>

        <PriceWrap>
          <BoxHeading>Academic & Skill</BoxHeading>
          <PriceImage src={PriceImage4} alt="PriceImage" />
          <PriceHeading>&#x20A6;8999 per hour</PriceHeading>
          <ul>
            <li>Lesson & Tech skill</li>
            <li>5% free data allowance</li>
            <li>Certificate of Completion</li>
          </ul>
          <PayButton to="/form" onClick={toggleHome}>Apply</PayButton>
        </PriceWrap>
      </PricingWrapper>
      <Link to="/form" onClick={toggleHome}>
        <HomeLesson>Chat us or fill this form</HomeLesson>
      </Link>
    </PricingContainer>
  );
};

export default AllPrices;
