import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContactSection = styled.section`
  background-color: #c7e6f8;
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5em;
  padding-inline: 1em;

  @media screen and (min-width: 60em) {
    padding-top: 4em;
  }
  @media screen and (min-width: 75em) {
    padding-top: 0;
  }
`;

export const FormContainer = styled.div`
  max-width: 100%;
  background: #fff;
  margin-bottom: -4.8em;
  z-index: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 5px;

  @media screen and (min-width: 48em) {
    margin-bottom: 0em;
    border-radius: 5px 5px 0 0;
  }
`;

export const FormHeader = styled.h2`
  color: #053146;
  opacity: 0.9;
  margin-bottom: 0.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.2;

  @media screen and (min-width: 48em) {
    font-size: 1.6rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  padding: 1.4em;

  @media screen and (min-width: 48em) {
    max-width: 600px;
    margin: auto;
  }

  @media screen and (min-width: 60em) {
    max-width: 700px;
  }

  hr {
    border: 2px solid #840d0d;
  }
`;

export const Input = styled.input`
  padding: 0.6em;
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
  outline: none;
  font-size: 1rem;
`;

export const TextArea = styled.textarea`
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 0.8em;
  height: 6em;
  resize: none;
  outline: none;
  font-size: 1rem;
  width: 100%;

  &::placeholder {
    font-size: 1.14rem;
  }

  @media screen and (min-width: 48em) {
    &::placeholder {
      font-size: 1.2rem;
    }
  }
`;

export const Button = styled.input`
  padding: 0.8em;
  border-radius: 5px;
  margin-top: 0.4em;
  cursor: pointer;
  background-color: #053146;
  font-size: 1.3rem;
  color: #fff;
  border: none;

  &:hover {
    scale: 0.99;
    background: linear-gradient(
      148deg,
      rgba(45, 98, 122, 1) 6%,
      rgba(14, 70, 97, 1) 34%,
      rgba(5, 48, 69, 1) 66%,
      rgba(5, 48, 69, 1) 100%
    );
    transition: all 300ms ease-in-out;
  }
`;

export const Select = styled.select`
  padding-block: 0.5em;
  border: none;
  outline: none;
  border-bottom: 4px solid #999;
  font-size: 1rem;
  line-height: 2;
  background: transparent;
  padding-block: 0.5em;

  @media screen and (min-width: 48em) {
    font-size: 1.1rem;
  }
`;

export const Option = styled.option`
  background: #484e50;
  color: #fff;
  font-size: 1rem;
`;

export const InputLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  label {
    margin-left: 5px;
    font-size: 1.1rem;
  }
`;

export const RadioLabel = styled(InputLabel)``;

export const InputGroup = styled(InputLabel)`
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8em;

  @media screen and (min-width: 30em) {
    flex-direction: row;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8em;

  @media screen and (min-width: 30em) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2em;
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8em;
`;

export const SelectSubjects = styled(ScheduleContainer)``;

export const Linked = styled(Link)`
  position: absolute;
  top: 0.6em;
  left: 0;
  z-index: 2;
`;

export const LinkToPrice = styled(Link)`
  position: absolute;
  top: 0.8em;
  right: 1em;
  z-index: 2;
  font-size: 1.15rem;
  color: #053146;
  font-family: "Lobster", cursive, sans-serif;
  
  @media screen and (min-width: 20em) {
    font-size: 1.3rem;
  }
  @media screen and (min-width: 48em) {
    font-size: 1.6rem;
  }
`;

export const ImageLink = styled.div`
  max-width: 140px;
  padding: 0.5em 1em;

  @media screen and (min-width: 20em) {
    max-width: 160px;
  }
  @media screen and (min-width: 48em) {
    max-width: 180px;
  }

  img {
    width: 100%;
  }
`;

export const Fieldset = styled.fieldset`
  padding: 0.4em;
`;

export const H2 = styled.h2`
  font-size: 1.2rem;
  margin-bottom: -0.1em;
  opacity: 0.88;

  @media screen and (min-width: 48em) {
    font-size: 1.3rem;
  }
`;

export const H22 = styled(H2)`
  margin-bottom: 0.6em;
`;

export const CheckBox = styled.input`
  width: 16px;
  height: 16px;
`;

export const RadioInput = styled.input`
  width: 18px;
  height: 18px;
`;
