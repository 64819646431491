import { useState } from "react";
import styled from "styled-components";
import LOGO from "../images/logo-default.png";
import LOGO2 from "../images/logo-white.png";
import { Link } from "react-router-dom";

const LogoContainer = styled.figure`
  width: ${({ bgColor }) => (bgColor ? "8em" : "7em")};
  background-color: ${({ bgColor }) => (bgColor ? "transparent" : "#f9f9f9")};

  @media screen and (max-width: 30em) {
    width: 6.6em;
  }
`;

const LogoImgage = styled.img`
  max-width: 100%;
`;

const Logo = () => {
  const [bgColor] = useState(true);
  
  return (
    <LogoContainer bgColor={bgColor}>
      <Link to="/">
        <LogoImgage src={bgColor ? LOGO2 : LOGO} alt="LOGO" />
      </Link>
    </LogoContainer>
  );
};

export default Logo;
