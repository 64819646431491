import Logo from "../LogoElements";
// import IMG from "../../images/bg-1.png";
import IMG from "../../images/home-tutor.png";
import {
  HeroContainer,
  HeroHeader,
  Para,
  Info,
  Image,
  Rounded,
  Header,
  LogoContainer
} from "./GetStartedElements";

const Hero = () => {
  return (
    <HeroContainer>
      <Header>
        <LogoContainer>
          <Logo bgColor="true" />
        </LogoContainer>
      </Header>
      <Info>
        <HeroHeader>Simple & Straightforward</HeroHeader>
        <Para>
          Every child can start — no debit or credit card needed.
        </Para>
      </Info>
      <Image src={IMG} alt="IMG" />
      <Rounded></Rounded>
    </HeroContainer>
  );
};

export default Hero;
